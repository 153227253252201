import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { startTransition, StrictMode } from "react";

if (webConfig.environment === "production") {
  Sentry.init({
    dsn: "https://a66fdc66195f43b5af44bf4af297d1fb@o457803.ingest.sentry.io/6599348",
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: webConfig.environment,
    release: webConfig.revision,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration(),
    ],
    autoSessionTracking: true,
    // ...
  });
}

function eventHandler(event: any) {
  try {
    event.preventDefault();
    installPrompt = event;
  } catch (error) {
    console.error(error);
  }
}

window.addEventListener("beforeinstallprompt", eventHandler);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/sw.js");
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
